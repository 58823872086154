<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-col md="12" col="12" class="pa-3">
        <v-row>
          <!-- Detail Member Card -->
          <v-col
            sm="8"
            cols="12"
            class="pb-0 mb-2"
            style="border: 1px solid #e0e0e0; border-radius: 10px"
            v-if="cardsetting.profileUrl"
          >
            <div
              style="
                float: left;
                width: 40%;
                overflow: hidden;
                margin-top: 12px;
              "
            >
              <p class="mb-2">{{ $t("image") }}</p>
              <template>
                <input
                  type="file"
                  name="image"
                  class="file_width"
                  id="image"
                  accept=".png, .jpg, .jpeg"
                  ref="fileupload"
                  @change="onCardProfileChange"
                />
              </template>
            </div>

            <div style="float: right; width: 60%; overflow: hidden">
              <v-img
                height="auto"
                aspect-ratio="1.7"
                style="border-radius: 20px"
                contain
                :src="localMembershipCard.profileUrl"
              ></v-img>
            </div>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.dob">
            <label>{{ $t("dob") }}</label>
            <app-datepicker
              :initialDate="localMembershipCard.activateDob"
              @emitDate="localMembershipCard.activateDob = $event"
            />

            <v-select
              class="mt-1"
              :label="$t('nationality')"
              item-text="name"
              item-value="code"
              v-model="localMembershipCard.activateNationality"
              :items="nationalities"
              :placeholder="$t('select')"
              outlined
            >
            </v-select>
            <v-select
              class="mt-1"
              :label="$t('gender')"
              item-text="name"
              item-value="id"
              v-model="localMembershipCard.activateGender"
              :items="genders"
              :placeholder="$t('select')"
              retrun-object
              outlined
            >
            </v-select>
            <v-text-field
              :label="$t('email')"
              required
              outlined
              autocomplete="false"
              v-model="localMembershipCard.activateEmail"
              append-icon="mdi-email-fast-outline"
              type="text"
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="4" class="pb-0">
            <v-text-field
              :label="$t('card_number')"
              required
              outlined
              v-model="localMembershipCard.cardNumber"
              @change="searchDublicateCard"
              append-icon="mdi-credit-card-settings-outline"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.phoneNum">
            <v-text-field
              :label="$t('phone_number')"
              required
              outlined
              v-model="localMembershipCard.activatePhone"
              append-icon="mdi-phone-outline"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0">
            <v-text-field
              :label="$t('name')"
              required
              outlined
              v-model="localMembershipCard.activateName"
              append-icon="mdi-account"
              type="text"
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.group">
            <v-select
              class="mt-1"
              :label="$t('group')"
              item-text="name"
              :disabled="true"
              v-model="localMembershipCard.group"
              :items="cardsGroup"
              :placeholder="$t('select')"
              return-object
              outlined
            >
            </v-select>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.nature">
            <v-select
              class="mt-1"
              item-text="name"
              item-value="id"
              :disabled="true"
              v-model="localMembershipCard.nature" 
              :items="natures"
              :label="$t('nature')"
              outlined
            >
            </v-select>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.orderNum">
            <v-text-field
              :label="$t('card_order_number')"
              required
              outlined
              :disabled="true"
              v-model="localMembershipCard.orderNumber"
              append-icon="mdi-order-bool-ascending"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.vattinNum">
            <v-text-field
              :label="$t('vattin_number')"
              outlined
              v-model="localMembershipCard.vatnum"
              append-icon="mdi-order-bool-ascending"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.nfc">
            <v-text-field
              :label="$t('nfc_chip')"
              required
              outlined
              aria-autocomplete="false"
              autocomplete="false"
              v-model="localMembershipCard.nfcNumber"
              append-icon="mdi-nfc-search-variant"
              type="password"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.level">
            <v-text-field
              :label="$t('level')"
              required
              outlined
              v-model="localMembershipCard.cardLevel"
              append-icon="mdi-account-school"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.point">
            <v-text-field
              :label="$t('_open_amount')"
              outlined
              v-model="localMembershipCard.totalPoint"
              append-icon="mdi-star-four-points-circle"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.creditLimit">
            <v-text-field
              :label="$t('limit_per_day')"
              outlined
              v-model="localMembershipCard.limitAmount"
              append-icon="mdi-car-speed-limiter"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="4" class="pb-0" v-if="cardsetting.credit">
            <v-text-field
              :label="$t('credit_')"
              outlined
              v-model="localMembershipCard.creditAmount"
              append-icon="mdi-credit-card"
              type="text"
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="4" class="py-0 kendo_dropdown_custom">
            <label class="label mb-0">{{
              $t("subscription_start_date")
            }}</label>
            <app-datepicker
              :initialDate="localMembershipCard.subscription.startDate"
              @emitDate="localMembershipCard.subscription.startDate = $event"
            />
          </v-col>
          <v-col sm="4" cols="4" class="py-0 kendo_dropdown_custom">
            <label class="label mb-0">{{ $t("subscription_end_date") }}</label>
            <app-datepicker
              :initialDate="localMembershipCard.subscription.endDate"
              @emitDate="localMembershipCard.subscription.endDate = $event"
            />
          </v-col>
          <v-col sm="12" cols="12" class="pb-0" v-if="cardsetting.address">
            <v-text-field
              :label="$t('address')"
              required
              outlined
              v-model="localMembershipCard.address"
              append-icon="mdi-map-marker"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col sm="12" cols="12" class="pt-5">
            <v-btn
              color="primary"
              class="float-right"
              @click="updateMemberCard"
              >{{ $t("save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import { i18n } from "@/i18n";
import DatePickerComponent from "@/components/custom_templates/DatePickerComponent";
import CardModel from "@/scripts/loyalty/model/Card.js";

const cookieJS = require("@/cookie.js");
const cookie = cookieJS.getCookie();
const loyaltyHandler = require("@/scripts/loyalty/handler/loyaltyHandler");
const instituteId =
  localStorage.getItem("instituteId") != null
    ? localStorage.getItem("instituteId")
    : "";
export default {
  name: "membership_card",
  props: ["membershipCard", "cardsetting"],
  components: {
    "app-datepicker": DatePickerComponent,
  },
  data() {
    return {
      cardsGroup: [],
      nationalities: [],
      loggedUser: {
        id: cookie.creator,
        name: cookie.email,
      },
      localMembershipCard: { ...this.membershipCard } 
    };
  },
  watch: {
    membershipCard: {
      handler(newVal) {
        this.localMembershipCard = { ...newVal };
      },
      deep: true,
    },
  },
  methods: {
    searchDublicateCard() {
      if (this.localMembershipCard.cardNumber) {
        let searchData = {
          type: "number",
          searchNum: this.localMembershipCard.cardNumber,
        };
        loyaltyHandler.searchCard(searchData).then((res) => {
          let d = res.data.data;
          if (d.length > 0) {
            this.$snotify.error(i18n.t("card_number_exist"));
          }
        });
      }
    },
    loadCardGroup() {
      window.localStorage.getItem(instituteId + "cardgroup")
        ? (this.cardsGroup = JSON.parse(
            window.localStorage.getItem(instituteId + "cardgroup")
          ))
        : (this.cardsGroup = []);
      window.console.log(this.cardsetting, "Card Setting");
      window.console.log(this.cardsGroup, "Card Group");
    },
    loadNationalities() {
      window.localStorage.getItem(instituteId + "national")
        ? (this.nationalities = JSON.parse(
            window.localStorage.getItem(instituteId + "national")
          ))
        : (this.nationalities = []);
    },
    onCardProfileChange(e) {
      let input = e.target;
      if (input.files && input.files[0]) {
        if (input.files[0].size > 1024 * 1024) {
          e.preventDefault();
          alert("File too big (> 1MB)");
          this.localMembershipCard.profile = "";
          this.$refs.fileupload.value = null;
        } else {
          let reader = new FileReader();
          reader.onloadend = (e) => {
            this.localMembershipCard.profile = e.target.result;
          };
          reader.readAsBinaryString(input.files[0]);
          this.localMembershipCard.profileUrl = URL.createObjectURL(
            e.target.files[0]
          );
          this.localMembershipCard.isEditImg = true;
        }
      }
    },
    async updateMemberCard() {
      window.console.log(this.$refs.form, "form ref");

      if (!this.$refs.form.validate()) {
        this.$refs.form.validate();
        return;
      }
      // check empty field
      if (this.localMembershipCard.cardNumber == "") {
        this.$swal({
          title: i18n.t("card_number_required"),
          text: i18n.t("check_card_number"),
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#4d4848",
          cancelButtonColor: "#eb0238",
          confirmButtonText: i18n.t("ok"),
        });
        return;
      }

      this.localMembershipCard.user = this.loggedUser;
      this.localMembershipCard.id = this.localMembershipCard.pk;
      this.showLoading = true;
      loyaltyHandler
        .loyaltyCardCreate(new CardModel(this.localMembershipCard))
        .then((response) => {
          this.showLoading = false;
          if (response.data.statusCode === 201) {
            this.showLoading = false;
            this.$swal({
              position: "products",
              icon: "success",
              title: i18n.t("your_work_has_been_saved"),
              showConfirmButton: true,
              confirmButtonColor: "#4d4848",
              cancelButtonColor: "#eb0238",
              confirmButtonText: i18n.t("ok"),
            });
            setTimeout(() => {
              this.clear();
            }, 10);
          }
        })
        .catch((e) => {
          this.showLoading = false;
          this.$snotify.error("Something went wrong");
          this.errors.push(e);
        });
    },
    clear() {
      this.localMembershipCard.isEditImg = false;
    },
  },
  computed: {
    genders() {
      return [
        { id: "male", name: i18n.t("male") },
        { id: "female", name: i18n.t("female") },
      ];
    },
    natures() {
      return [
        { id: "point_card", name: this.$t("point_card") },
        { id: "discount_card", name: this.$t("discount_card") },
        { id: "credit_card", name: this.$t("credit_card") },
        { id: "membership_card", name: this.$t("membership_card") },
      ];
    },
  },
  mounted() {
    this.loadCardGroup();
    this.loadNationalities();
  },
};
</script>
